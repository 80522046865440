import { addLocale, useLocale as ttagUseLocale } from 'ttag';

const DEFAULT_LANGUAGE = 'en';
const LOCALE_COOKIE_NAME = 'DOCS_PORTAL_LANG_CODE';

const LANGUAGES = [
  {
    key: 'en',
    title: 'English',
    iconClass: 'english-icon',
    iconStyle: 'regular',
  },
  {
    key: 'ja',
    title: 'Japanese',
    iconClass: 'japanese-icon',
    iconStyle: 'regular',
  },
];

export const getAvailableLanguages = () => LANGUAGES;

const isLocaleAvailable = (locale: string) => {
  const availableLocales = getAvailableLanguages().map((item) => item.key);
  return availableLocales.includes(locale);
};

export const getCurrentLanguage = () => {
  let locale = window.location.pathname.split('/')[1]
    ? window.location.pathname.split('/')[1]
    : (window.localStorage.getItem(LOCALE_COOKIE_NAME) as string);
  if (!isLocaleAvailable(locale)) {
    locale = DEFAULT_LANGUAGE;
  }
  return locale;
};

export const setLanguage = (locale: string) => {
  if (isLocaleAvailable(locale)) {
    ttagUseLocale(locale);
    window.localStorage.setItem(LOCALE_COOKIE_NAME, locale);
  }
};

export const setupLocale = () => {
  LANGUAGES.forEach(({ key }) => {
    if (key !== DEFAULT_LANGUAGE) {
      // eslint-disable-next-line
      const translationObjEs = require(`translation/${key}.po.json`);
      addLocale(key, translationObjEs);
    }
  });
};
