import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as GenaiIcon } from 'assets/images/genai.svg';
import { ReactComponent as VectorDatabaseIcon } from 'assets/images/api/quickstart.svg';
import { ReactComponent as PlaygroundIcon } from 'assets/images/prompting.svg';
import { ReactComponent as CodeIcon } from 'assets/images/code-based.svg';

const cardsContent = (lang: string) => [
  {
    key: 'use-genai',
    icon: <GenaiIcon />,
    title: t`Use Generative AI`,
    description: t`Review GenAI functionality and sample use cases`,
    link: t`/${lang}/docs/gen-ai/genai-welcome/index.html`,
  },
  {
    key: 'vector-databases',
    icon: <VectorDatabaseIcon />,
    title: t`Vector databases`,
    description: t`Create vector databases, LLM blueprints, and GenAI deployments`,
    link: t`/${lang}/docs/gen-ai/vector-database/index.html`,
  },
  {
    key: 'playground-tools',
    icon: <PlaygroundIcon />,
    title: t`Playground tools`,
    description: t`Create LLM blueprints, moderations, and deployments`,
    link: t`/${lang}/docs/gen-ai/playground-tools/index.html`,
  },
  {
    key: 'code-based-genai',
    icon: <CodeIcon />,
    title: t`Code-based GenAI`,
    description: t`Use code-based workflows to implement GenAI`,
    link: t`/${lang}/docs/gen-ai/genai-code/index.html`,
  },
];

const GenAI: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`Generative AI documentation`}
      subtitle={t`Learn every aspect of the DataRobot GenAI workflow, from creating vector databases to building LLM blueprints.`}
      cardsContent={cardsContent(lang)}
      cardsWidth="large"
    />
  );
};

export default GenAI;
