import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as QuickstartIcon } from 'assets/images/api/quickstart.svg';
import { ReactComponent as RestApiIcon } from 'assets/images/api/rest_api.svg';
import { ReactComponent as ReleaseIcon } from 'assets/images/platform/release.svg';

const cardsContent = (lang: string) => [
  {
    key: 'cloud-releases',
    icon: <QuickstartIcon />,
    title: t`SaaS releases`,
    description: t`New features for single- and multi-tenant managed deployments`,
    link: t`/${lang}/docs/release/cloud-history/index.html`,
  },
  {
    key: 'deprecations',
    icon: <RestApiIcon />,
    title: t`Deprecations and migrations`,
    description: t`Learn the how and when of feature deprecation`,
    link: t`/${lang}/docs/release/deprecations-and-migrations/index.html`,
  },
  {
    key: 'release-notes',
    icon: <ReleaseIcon />,
    title: t`Self-managed releases`,
    description: t`New features for VPC and on-prem deployments`,
    link: t`/${lang}/docs/release/archive-release-notes/index.html`,
  },
];

const Releases: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`AI Platform Releases`}
      subtitle={t`Learn about new features for the DataRobot AI Platform, access public preview 
        documentation, and get detailed guidance related to deprecated features.`}
      cardsContent={cardsContent(lang)}
      cardsWidth="large"
    />
  );
};

export default Releases;
