import { FunctionComponent } from 'react';
import {
  DropdownMenu,
  DROPDOWN_MENU_SELECTBOX_SIZES,
} from '@datarobot/design-system/dropdown-menu';
import classNames from 'classnames';
import { getAvailableLanguages } from 'utils/i18n';

import './language-switcher.scss';

type LanguageSwitcherProps = {
  selectedLanguage: string;
  onUpdateLanguage: any;
  placement: any;
};

const LanguageSwitcher: FunctionComponent<LanguageSwitcherProps> = ({
  selectedLanguage,
  onUpdateLanguage,
  placement,
}: LanguageSwitcherProps) => {
  const selectedKey: any = getAvailableLanguages().find(
    (item) => item.key === selectedLanguage
  );
  return (
    <DropdownMenu
      contentClassName="language-switcher-icons"
      triggerClassName="language-trigger-icons"
      options={getAvailableLanguages()}
      onSelect={onUpdateLanguage}
      placement={placement}
      selectbox={{ size: DROPDOWN_MENU_SELECTBOX_SIZES.LG }}
      triggerDynamicContent={() => (
        <div className="drop-item-title-content">
          <i
            className={classNames('drop-icon', selectedKey.iconClass)}
            aria-hidden="true"
          />
          {selectedKey.title}
        </div>
      )}
    />
  );
};

export default LanguageSwitcher;
