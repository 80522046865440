import {
  ReactElement,
  createContext,
  useState,
  useContext,
  useMemo,
} from 'react';

export interface Confidence {
  Correct: string;
  Digress: string;
  Grade: string;
  Incomplete: string;
  Incorrect: string;
  'No Answer': string;
}

export type DocsAssistData = {
  answer: string;
  citations: any[];
  confidence: Confidence;
  associationId: number;
};

const defaultData: DocsAssistData = {
  answer: '',
  citations: [],
  confidence: {
    Correct: '',
    Digress: '',
    Grade: '',
    Incomplete: '',
    Incorrect: '',
    'No Answer': '',
  },
  associationId: 0,
};

const defaultSource = 'All';
const defaultHasGraded = false;

const DocsAssistDataContext = createContext({
  data: defaultData,
  source: defaultSource,
  hasGraded: defaultHasGraded,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setGraded: (hasGraded: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSource: (source: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setData: (data: DocsAssistData) => {},
  clearData: () => {},
  resetData: () => {},
});

type DocsAssistDataProviderProps = {
  children: ReactElement;
};

export const DocsAssistDataProvider = ({
  children,
}: DocsAssistDataProviderProps) => {
  const [data, setData] = useState<DocsAssistData>(defaultData);
  const [source, setSource] = useState<string>(defaultSource);
  const [hasGraded, setGraded] = useState<boolean>(defaultHasGraded);

  const clearData = () => {
    setData(defaultData);
    setGraded(defaultHasGraded);
  };

  const resetData = () => {
    clearData();
    setSource(defaultSource);
  };

  const value = useMemo(
    () => ({
      data,
      source,
      hasGraded,
      setData,
      clearData,
      setSource,
      setGraded,
      resetData,
    }),
    [data, source, hasGraded]
  );

  return (
    <DocsAssistDataContext.Provider value={value}>
      {children}
    </DocsAssistDataContext.Provider>
  );
};

export const useDocsAssistData = () => useContext(DocsAssistDataContext);
