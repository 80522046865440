import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// declare global {
//   interface Window {
//     snowplow: any | undefined;
//   }
// }

type SnowplowListenerProps = {
  children: JSX.Element[];
};

const SnowplowListener: FunctionComponent<SnowplowListenerProps> = ({
  children,
}: SnowplowListenerProps) => {
  const location = useLocation();
  useEffect(() => {
    if (window.snowplow) {
      window.snowplow(
        'trackPageView',
        `${location.pathname}${location.search}`
      );
    }
  }, [location]);

  return <>{children}</>;
};

export default SnowplowListener;
