import { useState, createContext } from 'react';
import { getCurrentLanguage, setLanguage } from 'utils/i18n';

export type LangContextType = {
  lang: string;
  switchLang: (ln: string) => void;
};

export const LangContext = createContext<LangContextType>({
  lang: '',
  switchLang: () => {},
});

const LangProvider = ({ children }: any) => {
  const [lang, setLang] = useState(getCurrentLanguage());

  const switchLang = (ln: string): void => {
    setLang(ln);
    setLanguage(ln);
  };

  return (
    <LangContext.Provider
      value={{
        lang,
        switchLang,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangProvider;
