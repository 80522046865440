import { useState } from 'react';
import { t } from 'ttag';

import { DocsAssist } from 'components/docs-assist';

import './docs-assist.scss';
import 'components/search/autocomplete.scss';

const DocsAssistPage = () => {
  const [searchValue, setSearchValue] = useState('');
  return (
    <div className="page-content">
      <h1 className="heading-01 title">{t`Docs Assist`}</h1>
      <div className="search-assist-page middle">
        <div className="search-assist-container docs-assist">
          <DocsAssist
            searchValue={searchValue}
            onChange={(value) => {
              setSearchValue(value);
            }}
            onClearSearch={() => {
              setSearchValue('');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DocsAssistPage;
