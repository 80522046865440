import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as DataIcon } from 'assets/images/platform/data.svg';
import { ReactComponent as ModelingIcon } from 'assets/images/platform/modeling.svg';
import { ReactComponent as PredictionsIcon } from 'assets/images/platform/predictions.svg';
import { ReactComponent as MlopsIcon } from 'assets/images/platform/mlops.svg';
import { ReactComponent as AppIcon } from 'assets/images/accelerators.svg';
import { ReactComponent as NotebookIcon } from 'assets/images/api/nc-tile-code.svg';

const cardsContent = (lang: string) => [
  {
    key: 'data',
    icon: <DataIcon />,
    title: t`Data`,
    description: t`Ingest, transform, and store your data`,
    link: t`/${lang}/docs/data/index.html`,
  },
  {
    key: 'modeling',
    icon: <ModelingIcon />,
    title: t`Modeling`,
    description: t`Build and analyze models`,
    link: t`/${lang}/docs/modeling/index.html`,
  },
  {
    key: 'mlops',
    icon: <MlopsIcon />,
    title: t`MLOps`,
    description: t`Deploy, monitor, manage, and govern models in production`,
    link: t`/${lang}/docs/mlops/index.html`,
  },
  {
    key: 'notebook',
    icon: <NotebookIcon />,
    title: t`DataRobot Notebooks`,
    description: t`Create interactive and executable computing documents`,
    link: t`/${lang}/docs/dr-notebooks/index.html`,
  },
  {
    key: 'predictions',
    icon: <PredictionsIcon />,
    title: t`Predictions`,
    description: t`Make predictions with the UI or API`,
    link: t`/${lang}/docs/predictions/index.html`,
  },
  {
    key: 'apps',
    icon: <AppIcon />,
    title: t`No-Code AI Apps`,
    description: t`Configure AI-powered applications and enable core DataRobot services`,
    link: t`/${lang}/docs/app-builder/index.html`,
  },
];

const Ui: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={t`Classic UI documentation`}
      subtitle={t`Learn every aspect of the classic DataRobot workflow, from importing data to deploying and managing models.`}
      cardsContent={cardsContent(lang)}
      cardsWidth="middle"
    />
  );
};

export default Ui;
