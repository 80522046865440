import { FunctionComponent } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { ReactComponent as NoResultsIcon } from 'assets/images/no-results.svg';
import { t } from 'ttag';

import './no-results.scss';

const NoResults: FunctionComponent = () => {
  const { results } = useInstantSearch();
  return (
    <div className="search-hits">
      <div className="horizontal-divider" />
      <div className="no-filter-results" test-id="algolia-no-results">
        <div className="no-results">
          <NoResultsIcon />
          <div className="description">
            <span className="paragraph-regular">{t`No results for `}</span>
            <span className="paragraph-bold">
              <b>{`“${results._state.query}“`}</b>
            </span>
            <span className="paragraph-regular">.</span>
            <p className="paragraph-regular">{t`Please try another search.`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export function NoResultsBoundary({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const { results } = useInstantSearch();

  // The `__isArtificial` flag makes sure to not display the No Results message
  // when no hits have been returned yet.
  if (!results.__isArtificial && results.nbHits === 0) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return <>{children}</>;
}

export default NoResults;
