import { FunctionComponent, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { LanguageSwitcher } from 'components/language-switcher';
import { ReactComponent as BurgerIcon } from 'assets/images/burger-button.svg';

import './sidebar.scss';

type LanguageProps = {
  key: string;
  title: string;
  iconClass: string;
  iconStyle: string;
};

type NavProps = {
  key: string;
  title: string;
  route: string;
};

type SidebarProps = {
  internalLinks: Array<NavProps>;
  lang: string;
  switchLang: Function;
};

const Sidebar: FunctionComponent<SidebarProps> = ({
  internalLinks,
  switchLang,
  lang,
}: SidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  function updateLanguage(option: LanguageProps): void {
    const url = location.pathname.split('/').slice(2).join('/');
    switchLang(option.key);
    navigate(`/${option.key}/${url}`);
  }

  function handleOnClose(): void {
    setMenuIsOpen(false);
  }

  function handleStateChange(state: { isOpen: boolean }): void {
    setMenuIsOpen(state.isOpen);
  }

  return (
    <div className="sidebar">
      <Menu
        right
        customBurgerIcon={<BurgerIcon />}
        onStateChange={(state) => handleStateChange(state)}
        isOpen={menuIsOpen}
      >
        <div className="language-switcher">
          <LanguageSwitcher
            selectedLanguage={lang}
            onUpdateLanguage={(option: LanguageProps) => updateLanguage(option)}
            placement="top"
          />
        </div>
        {internalLinks.map((item: NavProps) => (
          <div key={item.key}>
            <NavLink
              to={item.route}
              className="link heading-04"
              onClick={() => handleOnClose()}
            >
              {item.title}
            </NavLink>
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default Sidebar;
