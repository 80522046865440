import { FunctionComponent, useContext } from 'react';
import { c, t } from 'ttag';
import { PageLayout } from 'components/page-layout';
import { LangContext } from 'contexts/LanguageContext';
import { ReactComponent as SpotlightIcon } from 'assets/images/spotlight.svg';
import { ReactComponent as Rocket5Icon } from 'assets/images/rocket5-icon.svg';
import { ReactComponent as VideosIcon } from 'assets/images/videos.svg';
import { ReactComponent as GetHelpIcon } from 'assets/images/tutorials.svg';
import { ReactComponent as Eli5Icon } from 'assets/images/prompting.svg';
import { ReactComponent as GlossaryIcon } from 'assets/images/glossary.svg';

const cardsContent = (lang: string) => [
  {
    key: 'workbench-in-5',
    icon: <SpotlightIcon />,
    title: t`First time?`,
    description: t`Get oriented then try some starter exercises`,
    link: t`/${lang}/docs/get-started/gs-day0/index.html`,
  },
  {
    key: 'workbench-in-5',
    icon: <Rocket5Icon />,
    title: t`Walkthroughs`,
    description: t`Follow step-by-step guidance to learn about using DataRobot`,
    link: t`/${lang}/docs/get-started/gs-dr5/index.html`,
  },
  {
    key: 'videos',
    icon: <VideosIcon />,
    title: t`Videos`,
    description: t`Short videos for modeling success`,
    link: t`/${lang}/docs/get-started/gs-videos/index.html`,
  },
  {
    key: 'get-help',
    icon: <GetHelpIcon />,
    title: t`Get help`,
    description: t`Follow platform troubleshooting steps`,
    link: t`/${lang}/docs/get-started/troubleshooting/index.html`,
  },
  {
    key: 'eli5',
    icon: <Eli5Icon />,
    title: t`ELI5`,
    description: t`Commonly asked questions answered in ELI5 format`,
    link: t`/${lang}/docs/get-started/robot-to-robot/index.html`,
  },
  {
    key: 'glossary',
    icon: <GlossaryIcon />,
    title: t`Glossary`,
    description: t`DataRobot terms defined`,
    link: t`/${lang}/docs/get-started/glossary/index.html`,
  },
];

const GetStarted: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  return (
    <PageLayout
      title={c('get started').t`Get started`}
      subtitle={t`Access learning resources that help you get started in DataRobot,
        including workflow overviews, fundamental concept explanations, and video tutorials.`}
      cardsContent={cardsContent(lang)}
      cardsWidth="middle"
    />
  );
};

export default GetStarted;
