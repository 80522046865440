import axios from 'axios';

export const DOCS_ASSIST_ASK = `${window.location.origin}/docs-assist/ask`;
export const DOCS_ASSIST_GRADE = `${window.location.origin}/docs-assist/grade`;

export const getBotResults = (source: string, question: string) =>
  axios.post(DOCS_ASSIST_ASK, { source, question });

export const sendBotFeedback = (association: number, grade: string) =>
  axios.post(DOCS_ASSIST_GRADE, { association, grade });
