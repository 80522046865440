import { FunctionComponent } from 'react';
import { t } from 'ttag';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { getCurrentLanguage, setLanguage, setupLocale } from 'utils/i18n';
import LangProvider from 'contexts/LanguageContext';
import AppContent from './AppContent';

import './app.scss';

setupLocale();
setLanguage(getCurrentLanguage());

const App: FunctionComponent = () => (
  <LangProvider>
    <HelmetProvider>
      <div className="app" test-id="docs-app">
        <Helmet>
          <title>{t`DataRobot Product Documentation`}</title>
          <link rel="canonical" href="https://docs.datarobot.com/" />
          <meta
            name="description"
            content={t`Public documentation for DataRobot’s end-to-end AI platform.
         Access platform and API docs, tutorial content, and more from a single location.`}
          />
          <meta
            property="og:title"
            content={t`DataRobot Product Documentation`}
          />
          <meta
            property="og:description"
            content={t`Public documentation for DataRobot’s end-to-end AI platform.
         Access platform and API docs, tutorial content, and more from a single location.`}
          />
        </Helmet>
        <AppContent />
      </div>
    </HelmetProvider>
  </LangProvider>
);

export default App;
