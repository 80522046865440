import { FunctionComponent, useContext } from 'react';
import { t } from 'ttag';
import {
  InstantSearch,
  SearchBox,
  useHits,
  UseHitsProps,
  Pagination,
} from 'react-instantsearch';
import { getSearchResults } from 'services/search';
import { SearchRequest } from 'interfaces/search';
import { LangContext } from 'contexts/LanguageContext';
import { SmartFilter } from 'components/search/Filter';
import { HitUi } from 'components/search/HitUi';
import NoResults, { NoResultsBoundary } from 'components/search/NoResults';

import './search.scss';

function ShowHits(props: UseHitsProps) {
  const { items } = useHits({ ...props, escapeHTML: false });

  return (
    <div className="search-hits">
      {items.map((hit) => (
        <div key={hit.objectID}>
          <HitUi hit={hit as any} />
        </div>
      ))}
    </div>
  );
}

const SearchPageComponent: FunctionComponent = () => {
  const { lang } = useContext(LangContext);
  const searchClient = {
    search: (requests: Array<SearchRequest>) => {
      const filter =
        requests[0].params.facetFilters?.length > 0
          ? requests[0].params.facetFilters[0][0]
          : null;
      return getSearchResults(
        requests[0].params.query,
        filter,
        lang,
        requests[0].params.page
      ) as any;
    },
  };
  let timerId = undefined as any;
  const queryHook = (query: string, search: Function) => {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => search(query), 200);
  };

  return (
    <div className="page-content">
      <div className="algolia-search search-page" test-id="algolia-search">
        <InstantSearch indexName="q" searchClient={searchClient} routing={true}>
          <SearchBox
            placeholder={t`Search documentation`}
            test-id="algolia-search-input"
            queryHook={queryHook}
          />
          <SmartFilter attribute="itemType" />
          <NoResultsBoundary fallback={<NoResults />}>
            <ShowHits />
          </NoResultsBoundary>
          <Pagination />
        </InstantSearch>
      </div>
    </div>
  );
};

export default SearchPageComponent;
