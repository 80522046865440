import { createContext } from 'react';
import { useLocation } from 'react-router-dom';

export const DocsAssistContext = createContext(false);

const DocsAssistProvider = ({ children }: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const docsAssistEnabled = !!(
    searchParams.get('enableDocsBot') || searchParams.get('enableDocsAssist')
  );
  return (
    <DocsAssistContext.Provider value={docsAssistEnabled}>
      {children}
    </DocsAssistContext.Provider>
  );
};

export default DocsAssistProvider;
