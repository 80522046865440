import { FunctionComponent } from 'react';
import { ReactComponent as MlopsIcon } from 'assets/images/platform/mlops.svg';
import { ReactComponent as DataIcon } from 'assets/images/platform/data.svg';
import { ReactComponent as ModelingIcon } from 'assets/images/platform/modeling.svg';
import { ReactComponent as PredictionsIcon } from 'assets/images/platform/predictions.svg';
import { ReactComponent as ReleaseIcon } from 'assets/images/platform/release.svg';
import { ReactComponent as AdministrationIcon } from 'assets/images/platform/administration.svg';
import { ReactComponent as QuickstartIcon } from 'assets/images/tutorials/quickstart.svg';
import { ReactComponent as GlossaryIcon } from 'assets/images/glossary.svg';
import { ReactComponent as NotebooksIcon } from 'assets/images/notebooks.svg';
import { ReactComponent as ApiIcon } from 'assets/images/api.svg';
import { ReactComponent as TutorialsIcon } from 'assets/images/tutorials.svg';

type HitIconProps = {
  category: string;
};

const iconsList: any = {
  mlops: <MlopsIcon />,
  data: <DataIcon />,
  // TODO: implement a proper fix in CLS-221
  platform: <ModelingIcon />,
  modeling: <ModelingIcon />,
  predictions: <PredictionsIcon />,
  release: <ReleaseIcon />,
  admin: <AdministrationIcon />,
  quickstart: <QuickstartIcon />,
  glossary: <GlossaryIcon />,
  notebooks: <NotebooksIcon />,
  api: <ApiIcon />,
  tutorials: <TutorialsIcon />,
};

const ItemIcon: FunctionComponent<HitIconProps> = ({
  category,
}: HitIconProps) => (
  <span className="item-icon">
    {iconsList[category] ?? iconsList.tutorials}
  </span>
);

export default ItemIcon;
