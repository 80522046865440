import { FunctionComponent, useState } from 'react';
import {
  Stats,
  useInstantSearch,
  useRefinementList,
} from 'react-instantsearch';
import { Button, ACCENT_TYPES } from '@datarobot/design-system/button';
import { t, ngettext, msgid } from 'ttag';

import './filter.scss';

export function getCategoryName(itemType?: string): string | {} {
  const categories: { [category: string]: string } = {
    all: t`All`,
    api: t`API`,
    nextGen: t`NextGen`,
    genAi: t`GenAI`,
    release: t`Releases`,
    accelerators: t`AI accelerators`,
    classic: t`Classic`,
  };
  return itemType ? categories[itemType] : categories;
}

const Filter: FunctionComponent<{
  itemType: string | null;
  onSelect: Function;
}> = ({
  itemType,
  onSelect,
}: {
  itemType: string | null;
  onSelect: Function;
}) => (
  <>
    <div className="filter">
      <div className="paragraph-bold">{t`Search:`}</div>
      <div className="button-group">
        {Object.entries(getCategoryName())
          .filter(([key]) => key !== 'classic')
          .map(([key, value]) => {
            const filterType = key === 'all' ? null : key;
            return (
              <Button
                key={key}
                accentType={
                  itemType === filterType
                    ? ACCENT_TYPES.PRIMARY
                    : ACCENT_TYPES.COMMAND
                }
                className="action-item"
                onClick={() => {
                  onSelect(filterType);
                }}
              >
                {value}
              </Button>
            );
          })}
      </div>
    </div>
    <Stats
      translations={{
        rootElementText: ({ nbHits }: { nbHits: number }) =>
          ngettext(
            msgid`${nbHits} result found`,
            `${nbHits} results found`,
            nbHits
          ),
      }}
    />
  </>
);

export function SmartFilter(props: any) {
  const { indexUiState, setIndexUiState, refresh } = useInstantSearch();
  const [itemType, setItemType] = useState<string | null>(
    indexUiState.refinementList?.itemType?.[0] || null
  );
  const updateItemType = (newItemType: string | null) => {
    setItemType(newItemType);
    setIndexUiState({
      ...indexUiState,
      page: 1,
      refinementList: { itemType: newItemType ? [newItemType] : [] },
    });
    refresh();
  };
  useRefinementList(props);

  return <Filter itemType={itemType} onSelect={updateItemType} />;
}

export default Filter;
